import React, { useState, useEffect } from 'react';
import { Paper, Group, Text, Stack, Select, Checkbox } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink, IconButterfly } from '@tabler/icons-react';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import { ChannelPicker } from '../../components/ChannelPicker';
import { getChannelModelTopicsSwarmData, getChannelYouTubeTopicsSwarmData, getChannelTypesSwarmData, getChannelAgeSwarmData, getChannelSummary, getChannelLengthSwarmData } from '../../../../utils/api';
import { SwarmChart } from '../../components/SwarmChart';
import { DataItem, CategoryType } from '../../../../utils/swarmtypes';

const navItems = [
  { title: 'Channels', href: '/channels/channel' },
  { title: 'Performance', href: '/channels/performance/two-d' },
  { title: '2D', href: '/channels/performance/two-d' },
  { title: 'Compare 2D', href: '/channels/performance/compare-two-d' },
  { title: 'Treemap', href: '/channels/performance/treemap' },
  { title: 'Swarm', href: '/channels/performance/swarm' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Channels-Swarm-1216777fc9558017b783c9fe0ee697a2', isExternal: true },
];

interface ChannelSummary {
  channelName: string;
  contentTypes: string[];
}

export function PerformanceSwarm() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<CategoryType>('types');
  const [excludeShorts, setExcludeShorts] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<DataItem[]>([]);
  const [channelSummary, setChannelSummary] = useState<ChannelSummary | null>(null);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedChannel) return;

      setLoading(true);
      setError(null);

      let apiCall;
      switch (selectedCategory) {
        case 'model':
          apiCall = getChannelModelTopicsSwarmData;
          break;
        case 'length':
          apiCall = getChannelLengthSwarmData;
          break;
        case 'youtube':
          apiCall = getChannelYouTubeTopicsSwarmData;
          break;
        case 'types':
          apiCall = getChannelTypesSwarmData;
          break;
        case 'age':
          apiCall = getChannelAgeSwarmData;
          break;
      }

      try {
        const [dataResponse, summaryResponse] = await Promise.all([
          apiCall(selectedChannel, 'views', selectedCategory !== 'types' ? excludeShorts : false),
          getChannelSummary(selectedChannel)
        ]);
        
        if (dataResponse.data && dataResponse.data.length > 0) {
          setData(dataResponse.data);
          setChannelSummary(summaryResponse.data);
        } else {
          setError('No data available for the selected options.');
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedChannel, selectedCategory, excludeShorts]);

  const getChartTitle = (category: CategoryType) => {
    const channelName = channelSummary?.channelName || 'Selected Channel';
    switch (category) {
      case 'model':
        return `Model Topics for ${channelName}`;
      case 'length':
        return `Video Length for ${channelName}`;
      case 'youtube':
        return `YouTube Topics for ${channelName}`;
      case 'types':
        return `Content Types for ${channelName}`;
      case 'age':
        return `Content Age for ${channelName}`;
      default:
        return `Swarm Chart for ${channelName}`;
    }
  };

  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 3 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 2 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 2 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 2 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 2 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
        </React.Fragment>
        ))}
      </Group>
        <Stack gap="xs">
          <Group>
            <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
            <Select
              value={selectedCategory}
              onChange={(value) => setSelectedCategory(value as CategoryType)}
              data={[
                { value: 'types', label: 'Content Types' },
                { value: 'length', label: 'Video Length' },
                { value: 'model', label: 'Model Topics' },
                { value: 'youtube', label: 'YouTube Topics' },
                { value: 'age', label: 'Content Age' },
              ]}
            />
            {selectedCategory !== 'types' && (
              <Checkbox
                label="Exclude Shorts"
                checked={excludeShorts}
                onChange={(event) => setExcludeShorts(event.currentTarget.checked)}
              />
            )}
          </Group>
          <Paper
                p="md" 
                radius="md" 
                withBorder
                style={{ 
                      transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
                      e.currentTarget.style.transform = 'translateY(-5px)';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.boxShadow = '';
                      e.currentTarget.style.transform = '';
                    }}
                  >
          {loading ? (
            <SkeletonLoader count={1} height={600} radius="sm" />
          ) : error ? (
            <Text color="red">{error}</Text>
          ) : data.length > 0 ? (
            <>
            <Group align="center" mb="sm">
              <IconButterfly size={20} />
              <Text size="md" fw={400} fs="italic">
                {getChartTitle(selectedCategory)}
              </Text>
            </Group>
            <SwarmChart 
              data={data} 
              category={selectedCategory}
            />
            </>
          ) : (
            <Text>Select a channel to get started, plus choose what you want to see and if you want to exclude shorts if you like.</Text>
          )}
        </Paper>
        </Stack>
    </Stack>
  );
}