import React, { useMemo, useState } from 'react';
import { Group, Text, SegmentedControl, Stack, HoverCard, Box, Paper } from '@mantine/core';
import { IconChartHistogram, IconChevronDown } from '@tabler/icons-react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
  ReferenceArea,
  Label,
  Tooltip
} from 'recharts';
import { formatNumber, formatDate } from '../../../utils/formatter';
import { ContentTypeBadge } from './ContentTypeBadge';

interface VideoData {
  videoId: string;
  title: string;
  views: number;
  type: string;
  publishDate: string;
}

interface DistributionBin {
  binStart: number;
  binEnd: number;
  count: number;
  percentage: number;
  videos: VideoData[];
  stdDevRange?: string;
  zScore?: number;
  displayLabel: string;  // Add this for consistent axis labels
}

interface ViewsDistributionChartProps {
  data: VideoData[];
  channelName: string;
  medianViews: number;
  meanViews: number;
}

type AxisType = 'raw' | 'stddev';
type ViewType = 'distribution' | 'contribution';

const calculateStdDev = (data: VideoData[], mean: number): number => {
  if (!data.length) return 0;
  const squareDiffs = data.map(video => Math.pow(video.views - mean, 2));
  const avgSquareDiff = squareDiffs.reduce((sum, diff) => sum + diff, 0) / data.length;
  return Math.sqrt(avgSquareDiff);
};

const getStdDevRange = (zScore: number): string => {
  if (zScore < -2) return '< -2σ';
  if (zScore < -1) return '-2σ to -1σ';
  if (zScore < 0) return '-1σ to median';
  if (zScore < 1) return 'median to +1σ';
  if (zScore < 2) return '+1σ to +2σ';
  return '> +2σ';
};

const calculateBins = (
  data: VideoData[], 
  meanViews: number,
  axisType: AxisType,
  binCount = 20
): DistributionBin[] => {
  if (data.length === 0) return [];

  const stdDev = calculateStdDev(data, meanViews);
  const sortedData = [...data].sort((a, b) => a.views - b.views);
  const minViews = sortedData[0].views;
  const maxViews = sortedData[sortedData.length - 1].views;

  if (axisType === 'stddev') {
    // Create bins based on standard deviation ranges
    // Only create std dev bins if we have valid statistics
    if (stdDev === 0) {
      return [{ 
        binStart: 0, 
        binEnd: maxViews, 
        count: data.length, 
        percentage: 100, 
        videos: data,
        stdDevRange: 'All Videos',
        displayLabel: 'All Videos'
      }];
    }

    const stdDevBins: DistributionBin[] = [
      { binStart: -Infinity, binEnd: meanViews - (2 * stdDev), count: 0, percentage: 0, videos: [], stdDevRange: '< -2σ', displayLabel: '< -2σ' },
      { binStart: meanViews - (2 * stdDev), binEnd: meanViews - stdDev, count: 0, percentage: 0, videos: [], stdDevRange: '-2σ to -1σ', displayLabel: '-2σ to -1σ' },
      { binStart: meanViews - stdDev, binEnd: meanViews, count: 0, percentage: 0, videos: [], stdDevRange: '-1σ to median', displayLabel: '-1σ to median' },
      { binStart: meanViews, binEnd: meanViews + stdDev, count: 0, percentage: 0, videos: [], stdDevRange: 'median to +1σ', displayLabel: 'median to +1σ' },
      { binStart: meanViews + stdDev, binEnd: meanViews + (2 * stdDev), count: 0, percentage: 0, videos: [], stdDevRange: '+1σ to +2σ', displayLabel: '+1σ to +2σ' },
      { binStart: meanViews + (2 * stdDev), binEnd: Infinity, count: 0, percentage: 0, videos: [], stdDevRange: '> +2σ', displayLabel: '> +2σ' }
    ];

    const totalViews = data.reduce((sum, video) => sum + video.views, 0);
    
    data.forEach(video => {
      const zScore = (video.views - meanViews) / stdDev;
      const binIndex = Math.min(Math.floor(zScore + 2), 5);
      stdDevBins[binIndex].count++;
      stdDevBins[binIndex].videos.push(video);
      stdDevBins[binIndex].percentage += (video.views / totalViews) * 100;
    });

    return stdDevBins;
  } else {
    // Fixed 100k view buckets
    const BUCKET_SIZE = 100000;
    const numBuckets = Math.ceil(maxViews / BUCKET_SIZE);
    const bins: DistributionBin[] = Array.from({ length: numBuckets }, (_, i) => ({
      binStart: i * BUCKET_SIZE,
      binEnd: (i + 1) * BUCKET_SIZE,
      count: 0,
      percentage: 0,
      videos: [],
      displayLabel: `${formatNumber(i * BUCKET_SIZE)}-${formatNumber((i + 1) * BUCKET_SIZE)}`
    }));

    const totalViews = data.reduce((sum, video) => sum + video.views, 0);

    data.forEach(video => {
      const binIndex = Math.min(
        Math.floor(video.views / BUCKET_SIZE),
        numBuckets - 1
      );
      bins[binIndex].count++;
      bins[binIndex].videos.push(video);
      bins[binIndex].percentage += (video.views / totalViews) * 100;
    });

    return bins;
  }
};

const CustomTooltip: React.FC<any> = ({ active, payload, viewType }) => {
  if (!active || !payload || !payload[0]) return null;

  const data = payload[0].payload as DistributionBin;
  const sortedVideos = [...data.videos].sort((a, b) => b.views - a.views);

  return (
    <HoverCard width={300} shadow="md">
      <HoverCard.Target>
        <Paper p="xs" withBorder>
          <Stack>
            <Text size="sm" fw={500}>
              {data.stdDevRange || 
                `Views: ${formatNumber(data.binStart)} - ${formatNumber(data.binEnd)}`}
            </Text>
            <Group>
              <Text size="sm">Videos: {data.count}</Text>
              <Text size="sm">Contribution: {data.percentage.toFixed(2)}%</Text>
            </Group>
            {sortedVideos.slice(0, 3).map(video => (
              <Group key={video.videoId} align="center" wrap="nowrap">
                <ContentTypeBadge type={video.type} size="xs" />
                <Box>
                  <Text size="xs" lineClamp={1}>{video.title}</Text>
                  <Text size="xs" c="dimmed">{formatNumber(video.views)} views</Text>
                </Box>
              </Group>
            ))}
            <Group align="center">
              <IconChevronDown size={14} />
              <Text size="xs" c="dimmed">
                {data.videos.length > 3 && `+${data.videos.length - 3} more videos`}
              </Text>
            </Group>
          </Stack>
        </Paper>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Stack>
          {sortedVideos.slice(3).map(video => (
            <Group key={video.videoId} align="center" wrap="nowrap">
              <ContentTypeBadge type={video.type} size="xs" />
              <Box>
                <Text size="xs" lineClamp={1}>{video.title}</Text>
                <Text size="xs" c="dimmed">
                  {formatNumber(video.views)} views • {formatDate(video.publishDate)}
                </Text>
              </Box>
            </Group>
          ))}
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

const ViewsDistributionChart: React.FC<ViewsDistributionChartProps> = ({
  data,
  channelName,
  medianViews,
  meanViews
}) => {
  const [viewType, setViewType] = useState<ViewType>('distribution');
  const [axisType, setAxisType] = useState<AxisType>('raw');
  
  const bins = useMemo(() => 
    calculateBins(data, meanViews, axisType), 
    [data, meanViews, axisType]
  );

  const renderXAxisTick = ({ x, y, payload }: any) => (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={20}
        dy={16}
        textAnchor="middle"
        fill="#666"
        fontSize={12}
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );

  return (
    <Stack>
      <Group justify="space-between" align="center">
        <Group>
          <IconChartHistogram size={20} />
          <Text size="md" fw={500}>Views Distribution for {channelName}</Text>
        </Group>
        <Group>
          <SegmentedControl
            value={axisType}
            onChange={(value) => setAxisType(value as AxisType)}
            data={[
              { label: 'Raw Views', value: 'raw' },
              { label: 'Standard Deviations', value: 'stddev' }
            ]}
          />
          <SegmentedControl
            value={viewType}
            onChange={(value) => setViewType(value as ViewType)}
            data={[
              { label: 'Distribution', value: 'distribution' },
              { label: 'Contribution %', value: 'contribution' }
            ]}
          />
        </Group>
      </Group>

      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={bins}
          margin={{ top: 20, right: 30, left: 60, bottom: 60 }}
          barCategoryGap={1}  // Reduce gap between bars
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={axisType === 'stddev' ? 'stdDevRange' : 'binStart'}
            type="category"
            tick={renderXAxisTick}
            padding={{ left: 10, right: 10 }}
            label={{
              value: axisType === 'stddev' ? "Standard Deviations" : "Views",
              position: "bottom",
              offset: 40
            }}
          />
          <YAxis
            label={{
              value: viewType === 'distribution' ? "Number of Videos" : "% of Total Views",
              angle: -90,
              position: "insideLeft",
              offset: 10
            }}
          />

          <Tooltip content={<CustomTooltip viewType={viewType} />} />

          <Bar
            dataKey={viewType === 'distribution' ? 'count' : 'percentage'}
            fill="#FF0000"
            opacity={0.8}
          />

          {axisType === 'raw' && (
            <>
              <ReferenceLine
                x={medianViews}
                stroke="#0982eb"
                strokeDasharray="3 3"
              >
                <text
                  x={medianViews}
                  y={10}
                  fill="#0982eb"
                  textAnchor="start"
                  transform={`rotate(-30, ${medianViews}, 10)`}
                >
                  Median ({formatNumber(medianViews)})
                </text>
              </ReferenceLine>

              <ReferenceLine
                x={meanViews}
                stroke="#228B22"
                strokeDasharray="3 3"
              >
                <text
                  x={meanViews}
                  y={30}
                  fill="#228B22"
                  textAnchor="start"
                  transform={`rotate(-30, ${meanViews}, 30)`}
                >
                  Mean ({formatNumber(meanViews)})
                </text>
              </ReferenceLine>
            </>
          )}
        </BarChart>
      </ResponsiveContainer>
    </Stack>
  );
};

export default ViewsDistributionChart;