import React, { useState, useEffect } from 'react';
import { Text, Group, Stack, Paper, Select, Card } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink, IconChartBar } from '@tabler/icons-react';
import { ChannelPicker } from '../../components/ChannelPicker';
import { CriticalDayChart } from '../../components/CriticalDayBarChart';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import { 
  getChannelCriticalStatus, 
  getChannelCriticalDates,
  getCriticalDayData,
  ChannelStatus,
  CriticalDayData
} from '../../../../utils/api';
import { formatDate, formatNumber } from '../../../../utils/formatter';

const navItems = [
  { title: 'Channels', href: '/channels/channels/channel' },
  { title: 'Channels', href: '/channels/channels/channel' },
  { title: 'Channel', href: '/channels/channels/channel' },
  { title: 'Top Five', href: '/channels/channels/top-five' },
  { title: 'Compare Channels', href: '/channels/channels/compare' },
  { title: 'Timeline', href: '/channels/channels/timeline' },
  { title: 'Classification', href: '/channels/channels/classification' },
  { title: 'Going Critical...', href: '/channels/channels/critical-days' },
  { title: 'Distribution', href: '/channels/channels/views-distribution' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Critical-Days-1466777fc955800c8439ef507b95ec4f', isExternal: true },
];

export function ChannelCriticalDays() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [channelStatus, setChannelStatus] = useState<ChannelStatus | null>(null);
  const [criticalDates, setCriticalDates] = useState<string[]>([]);
  const [criticalDayData, setCriticalDayData] = useState<CriticalDayData | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (selectedChannel) {
      loadChannelData();
    } else {
      // Reset states when channel is deselected
      setChannelStatus(null);
      setCriticalDates([]);
      setSelectedDate(null);
      setCriticalDayData(null);
    }
  }, [selectedChannel]);

  useEffect(() => {
    if (selectedChannel && selectedDate) {
      loadCriticalDayData();
    } else {
      setCriticalDayData(null);
    }
  }, [selectedChannel, selectedDate]);

  const loadChannelData = async () => {
    if (!selectedChannel) return;
    
    setLoading(true);
    setError(null);

    try {
      const [statusResponse, datesResponse] = await Promise.all([
        getChannelCriticalStatus(selectedChannel),
        getChannelCriticalDates(selectedChannel)
      ]);

      setChannelStatus(statusResponse);
      setCriticalDates(datesResponse.dates);
      
      // Reset selected date when changing channels
      setSelectedDate(null);
    } catch (err) {
      setError('Failed to load channel data. Please try again.');
      console.error('Error loading channel data:', err);
    } finally {
      setLoading(false);
    }
  };

  const loadCriticalDayData = async () => {
    if (!selectedChannel || !selectedDate) return;
    
    setLoading(true);
    setError(null);

    try {
      const data = await getCriticalDayData(selectedChannel, selectedDate);
      setCriticalDayData(data);
    } catch (err) {
      setError('Failed to load critical day data. Please try again.');
      console.error('Error loading critical day data:', err);
    } finally {
      setLoading(false);
    }
  };

  const renderSummaryCard = () => (
    <Card p="md" radius="md" withBorder>
      <Stack>
        <Text size="lg" fw={500}>Channel Analysis</Text>
        <Text>
          Using a {channelStatus?.channelTier.toLowerCase()} channel threshold of{' '}
          {channelStatus?.summary?.appliedThreshold} standard deviations
        </Text>
        {channelStatus?.summary ? (
          <>
            <Text>Total Critical Days: {channelStatus.summary.totalCriticalDays}</Text>
            <Text>First Critical Day: {formatDate(channelStatus.summary.firstCriticalDay!)}</Text>
            <Text>Last Critical Day: {formatDate(channelStatus.summary.lastCriticalDay!)}</Text>
            <Text>Average Impact: {channelStatus.summary.averageSigma.toFixed(2)}σ</Text>
          </>
        ) : (
          <Text>{channelStatus?.message}</Text>
        )}
      </Stack>
    </Card>
  );

  const renderDayAnalysis = () => {
    if (!criticalDayData) return null;
  
    return (
      <Stack gap="md">
        <Card p="md" radius="md" withBorder>
          <Stack>
            <Text size="lg" fw={500}>
              Performance on {formatDate(criticalDayData.date)}
            </Text>
            <Text>
              The channel gained {formatNumber(criticalDayData.totalViewChange)} views on this day
            </Text>
            <Text>
              This was {formatNumber(criticalDayData.viewChangeAboveBaseline)} views above the baseline
              ({criticalDayData.sigma.toFixed(2)}σ)
            </Text>
          </Stack>
        </Card>
  
        <Paper p="md" radius="md" withBorder>
          <CriticalDayChart 
            data={[
              ...criticalDayData.contributors.core,
              ...criticalDayData.contributors.domain,
              ...criticalDayData.contributors.sphere,
              ...criticalDayData.contributors.periphery
            ]}
            totalViewChange={criticalDayData.totalViewChange}
            date={criticalDayData.date} // Added date prop
          />
        </Paper>
      </Stack>
    );
  };

  return (
    <Stack gap="md">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 3 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 2 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 2 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 2 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 2 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
          </React.Fragment>
        ))}
      </Group>

      <Stack gap="md">
        <Group>
          <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
          {criticalDates.length > 0 && (
            <Select
              value={selectedDate}
              onChange={setSelectedDate}
              data={criticalDates.map(date => ({
                value: date,
                label: formatDate(date)
              }))}
              placeholder="Select critical day"
              style={{ width: '200px' }}
            />
          )}
        </Group>

        {loading ? (
          <SkeletonLoader count={2} height={200} />
        ) : error ? (
          <Text color="red">{error}</Text>
        ) : channelStatus ? (
          <Stack gap="md">
            {renderSummaryCard()}
            {renderDayAnalysis()}
          </Stack>
        ) : (
          <Text>Select a channel to analyze critical days</Text>
        )}
      </Stack>
    </Stack>
  );
}