import React from 'react';
import { Text, Box, Stack, Group } from '@mantine/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Cell
} from 'recharts';
import { ContentTypeBadge } from './ContentTypeBadge';
import { formatNumber, formatDate } from '../../../utils/formatter';
import { ContributorVideo } from '../../../utils/api';
import { differenceInDays } from 'date-fns';

interface CriticalDayChartProps {
  data: ContributorVideo[];
  totalViewChange: number;
  date: string; // Critical day date
}

interface ProcessedVideoData {
  id: string;
  name: string;
  views: number;
  viewChange: number;
  percentageContribution: number;
  videoType: string;
  isGroup?: boolean;
  isUntracked?: boolean;
  publishDate?: string;
}

const contentTypeColors: Record<string, string> = {
  'Video': '#FF0000',
  'Short': '#FFA500',
  'Podcast': '#9C27B0',
  'Course': '#FF0000',
  'Live': '#007BFF',
  'Premiere': '#607D8B',
  'Music Video': '#FF0000',
  'Mixed': '#0d3fa6', // For grouped low contributors
  'Untracked': '#808080'
};

const CustomTooltip: React.FC<any> = ({ active, payload, criticalDate }) => {
  if (active && payload && payload.length) {
    const data: ProcessedVideoData = payload[0].payload;
    
    return (
      <Box 
        p="md" 
        bg="white" 
        style={{ 
          border: '1px solid #ccc', 
          borderRadius: '4px',
          maxWidth: '300px'
        }}
      >
        <Stack gap="xs">
          {data.isUntracked ? (
            <Text size="sm" fw={500}>Untracked Views</Text>
          ) : data.isGroup ? (
            <Text size="sm" fw={500}>Low Impact Videos</Text>
          ) : (
            <>
              <Text size="sm" fw={500} lineClamp={2}>{data.name}</Text>
              <ContentTypeBadge type={data.videoType} />
              {data.publishDate && (
                <>
                  <Text size="sm">
                    Published: {formatDate(data.publishDate)}
                  </Text>
                  <Text size="sm" c="dimmed">
                    {Math.abs(differenceInDays(new Date(criticalDate), new Date(data.publishDate)))} days 
                    {new Date(data.publishDate) > new Date(criticalDate) ? ' after' : ' before'} this day
                  </Text>
                </>
              )}
            </>
          )}
          <Text size="sm">
            Views Gained: <Text span fw={600}>{formatNumber(data.viewChange)}</Text>
          </Text>
          <Text size="sm">
            Contribution: {data.percentageContribution.toFixed(1)}%
          </Text>
        </Stack>
      </Box>
    );
  }
  return null;
};

export const CriticalDayChart: React.FC<CriticalDayChartProps> = ({ 
  data,
  totalViewChange,
  date
}) => {
  // Process and sort videos by view change
  const processedData: ProcessedVideoData[] = [];
  const lowContributors: ContributorVideo[] = [];

  // Calculate total tracked views
  const totalTrackedViews = data.reduce((sum, video) => sum + video.viewChange, 0);
  
  // Calculate untracked views
  const untrackedViews = totalViewChange - totalTrackedViews;

  // Sort by view change and separate low contributors
  data.forEach(video => {
    if (video.percentageContribution < 0.5) {
      lowContributors.push(video);
    } else {
      processedData.push({
        id: video.videoId,
        name: video.title,
        views: video.viewChange,
        viewChange: video.viewChange,
        percentageContribution: video.percentageContribution,
        videoType: video.videoType,
        publishDate: video.publishDate
      });
    }
  });

  // Sort by contribution
  processedData.sort((a, b) => b.viewChange - a.viewChange);

  // Add grouped low contributors if any exist
  if (lowContributors.length > 0) {
    const totalLowViews = lowContributors.reduce((sum, video) => 
      sum + video.viewChange, 0);
    const percentageContribution = (totalLowViews / totalViewChange) * 100;

    processedData.push({
      id: 'low-contributors',
      name: 'Videos < 0.5%',
      views: totalLowViews,
      viewChange: totalLowViews,
      percentageContribution,
      videoType: 'Mixed',
      isGroup: true
    });
  }

  // Add untracked views
  if (untrackedViews > 0) {
    processedData.push({
      id: 'untracked',
      name: 'Untracked',
      views: untrackedViews,
      viewChange: untrackedViews,
      percentageContribution: (untrackedViews / totalViewChange) * 100,
      videoType: 'Untracked',
      isUntracked: true
    });
  }

  return (
    <Stack gap="md">
      <Text size="lg" fw={500}>View Contributions</Text>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={processedData}
          margin={{ top: 20, right: 30, left: 60, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tick={false}  // Hide individual labels for cleaner look
            label={{
              value: "Contributing Videos",
              position: "bottom",
              offset: 20
            }}
          />
          <YAxis
            tickFormatter={formatNumber}
            label={{
              value: "Views Gained",
              angle: -90,
              position: "insideLeft",
              offset: -40
            }}
          />
          <Tooltip content={(props) => <CustomTooltip {...props} criticalDate={date} />} />
          <Bar
            dataKey="views"
            fill="#FF0000"
            label={{
              position: 'top',
              formatter: (value: number) => 
                `${((value / totalViewChange) * 100).toFixed(1)}%`
            }}
            isAnimationActive={true}
            animationDuration={2000}
          >
            {processedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={contentTypeColors[entry.isGroup ? 'Mixed' : entry.videoType] || '#808080'}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Stack>
  );
};