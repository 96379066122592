import React, { useState, useEffect } from 'react';
import { Paper, Group, Text, Stack } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { ChannelPicker } from '../../components/ChannelPicker';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import ViewsDistributionChart from '../../components/ChannelViewDistributionChart';
import { getChannelViewsDistribution, getChannelSummary } from '../../../../utils/api';

// Navigation items
const navItems = [
  { title: 'Channels', href: '/channels/channels/channel' },
  { title: 'Channels', href: '/channels/channels/channel' },
  { title: 'Channel', href: '/channels/channels/channel' },
  { title: 'Top Five', href: '/channels/channels/top-five' },
  { title: 'Compare Channels', href: '/channels/channels/compare' },
  { title: 'Timeline', href: '/channels/channels/timeline' },
  { title: 'Classification', href: '/channels/channels/classification' },
  { title: 'Going Critical...', href: '/channels/channels/critical-days' },
  { title: 'Distribution', href: '/channels/channels/views-distribution' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Views-Distribution-14d6777fc955801a85c7e441fd37b54d', isExternal: true },
];

interface ViewsDistributionData {
  videos: Array<{
    videoId: string;
    title: string;
    views: number;
    type: string;
    publishDate: string;
  }>;
  stats: {
    totalVideos: number;
    totalViews: number;
    meanViews: number;
    medianViews: number;
  };
}

export function ChannelViewsDistribution() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [channelName, setChannelName] = useState<string>('');
  const [distributionData, setDistributionData] = useState<ViewsDistributionData | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (selectedChannel) {
      fetchData();
    }
  }, [selectedChannel]);

  const fetchData = async () => {
    if (!selectedChannel) return;

    setLoading(true);
    setError(null);

    try {
      const [distributionResponse, summaryResponse] = await Promise.all([
        getChannelViewsDistribution(selectedChannel),
        getChannelSummary(selectedChannel)
      ]);

      setDistributionData(distributionResponse.data);
      setChannelName(summaryResponse.data.channelName);
    } catch (err) {
      console.error('Error fetching distribution data:', err);
      setError('Failed to fetch data. Please try again.');
      setDistributionData(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack gap="md">
      {/* Navigation */}
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 2 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 2 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 2 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
          </React.Fragment>
        ))}
      </Group>

      <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />

      {loading ? (
        <SkeletonLoader count={1} height={600} />
      ) : error ? (
        <Paper p="xl" radius="md" withBorder>
          <Text ta="center" size="lg" c="dimmed">
            {error}
          </Text>
        </Paper>
      ) : distributionData ? (
        <Paper
          p="md"
          radius="md"
          withBorder
          style={{
            transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
            e.currentTarget.style.transform = 'translateY(-5px)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.boxShadow = '';
            e.currentTarget.style.transform = '';
          }}
        >
          <ViewsDistributionChart
            data={distributionData.videos}
            channelName={channelName}
            medianViews={distributionData.stats.medianViews}
            meanViews={distributionData.stats.meanViews}
          />
        </Paper>
      ) : (
        <Paper p="xl" radius="md" withBorder>
          <Text ta="center" size="lg" c="dimmed">
            Select a channel to view video distribution
          </Text>
        </Paper>
      )}
    </Stack>
  );
}