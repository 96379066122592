import React, { PureComponent, useMemo } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import { Text } from '@mantine/core';
import { DataItem, CategoryType } from '../../../utils/swarmtypes';
import { formatNumber, formatDate } from '../../../utils/formatter';
import * as d3 from 'd3';

interface SwarmChartProps {
  data: DataItem[];
  category: CategoryType;
}

class CustomizedAxisTick extends PureComponent<any> {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={12}>
          {payload.value}
        </text>
      </g>
    );
  }
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload as DataItem;
    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <Text size="sm" fw={500} fs="italic">{data.group}</Text>
        <Text size="sm" fw={500}>{data.title}</Text>
        <Text size="sm" c="dimmed">Published: {formatDate(data.publishDate)}</Text>
        <Text size="sm">Type: {data.videoType}</Text>
        <Text size="sm">Views: {formatNumber(data.views)}</Text>
      </div>
    );
  }
  return null;
};

const contentTypeColors = {
  'Video': '#FF0000',
  'Short': '#FFA500',
  'Podcast': '#9C27B0',
  'Live': '#007BFF',
  'Premiere': '#607D8B',
  'Course': '#FF0000',
  'Music Video': '#FF0000'
};

const generateColorPalette = (count: number): string[] => {
  return Array.from({ length: count }, (_, i) => 
    d3.rgb(d3.interpolateTurbo(i / (count - 1))).formatHex()
  );
};

const colorPalette = generateColorPalette(50);

const ageOrder = [
  'In the last week',
  'Between 1 week and 1 month',
  'Between 1 month and 3 months',
  'Between 3 months and 6 months',
  'Older than 6 months'
];

const lengthOrder = [
  'Video ≤ 1 minute',
  '1 minute < Video ≤ 2 minutes',
  '2 minutes < Video ≤ 3 minutes',
  '3 minutes < Video ≤ 5 minutes',
  '5 minutes < Video ≤ 10 minutes',
  '10 minutes < Video ≤ 15 minutes',
  '15 minutes < Video ≤ 20 minutes',
  '20 minutes < Video ≤ 25 minutes',
  '25 minutes < Video ≤ 30 minutes',
  '30 minutes < Video ≤ 35 minutes',
  '35 minutes < Video ≤ 40 minutes',
  '40 minutes < Video ≤ 45 minutes',
  '45 minutes < Video ≤ 60 minutes',
  '60 minutes < Video ≤ 120 minutes',
  'Video > 120 minutes'
];

export const SwarmChart: React.FC<SwarmChartProps> = ({ data, category }) => { 
  let groups = Array.from(new Set(data.map(item => item.group)));
  
  if (category === 'age') {
    groups = [
      'In the last week',
      'Between 1 week and 1 month',
      'Between 1 month and 3 months',
      'Between 3 months and 6 months',
      'Older than 6 months'
    ].filter(age => groups.includes(age));
  } else if (category === 'length') {
    groups = lengthOrder.filter(length => groups.includes(length));
  } else {
    groups.sort((a, b) => {
      const aTotal = data.filter(d => d.group === a).reduce((sum, d) => sum + d.views, 0);
      const bTotal = data.filter(d => d.group === b).reduce((sum, d) => sum + d.views, 0);
      return bTotal - aTotal;
    });
  }

  const getColor = useMemo(() => {
    const categoryColorMap = new Map<string, string>();

    return (group: string): string => {
      switch (category) {
        case 'types':
          return contentTypeColors[group as keyof typeof contentTypeColors] || '#808080';
        case 'model':
          if (group === 'Not Modelled') return '#808080';
          if (!categoryColorMap.has(group)) {
            categoryColorMap.set(group, colorPalette[categoryColorMap.size % colorPalette.length]);
          }
          return categoryColorMap.get(group)!;
        case 'youtube':
          if (group === 'No Topics Assigned By YouTube') return '#808080';
          if (!categoryColorMap.has(group)) {
            categoryColorMap.set(group, colorPalette[categoryColorMap.size % colorPalette.length]);
          }
          return categoryColorMap.get(group)!;
        case 'age':
          // Keep existing color logic for age
          return `rgb(${Math.round(255 * groups.indexOf(group) / (groups.length - 1))},0,${Math.round(255 * (groups.length - 1 - groups.indexOf(group)) / (groups.length - 1))})`;
        case 'length':
          return `rgb(${Math.round(255 * groups.indexOf(group) / (groups.length - 1))},0,${Math.round(255 * (groups.length - 1 - groups.indexOf(group)) / (groups.length - 1))})`;
        default:
          return '#808080';
      }
    };
  }, [category, groups]);

  return (
    <ResponsiveContainer width="100%" height={600}>
      <ScatterChart margin={{ top: 20, right: 20, bottom: 100, left: 30 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="group" 
          type="category"
          allowDuplicatedCategory={false}
          interval={0}
          tick={<CustomizedAxisTick />}
          height={60}
        />
        <YAxis 
          dataKey="views"
          name="Views" 
          type="number" 
          scale="log" 
          domain={['auto', 'auto']}
          tickFormatter={(value) => formatNumber(value)}
          label={{ value: "Views (note Log scale)", angle: -90, position: 'insideLeft', offset: -20 }}
        />
        <ZAxis range={[50, 400]} />
        <Tooltip content={<CustomTooltip />} />
        {groups.map((group) => (
          <Scatter
            key={group}
            name={group}
            data={data.filter(item => item.group === group)}
            fill={getColor(group)}
          />
        ))}
      </ScatterChart>
    </ResponsiveContainer>
  );
};