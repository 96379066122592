import React from 'react';
import { Text, Group, Stack, Container, Title, Paper, List } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';

const navItems = [
  { title: 'Docs', href: '/docs/new-one' },
  { title: 'New!', href: '/docs/new-one' },
  { title: 'Help Directory', href: '/docs/help' },
  { title: 'ReadMe', href: '/docs/readme', isExternal: false },
];

export function DocsNew() {
  const location = useLocation();

  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 1 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 1 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 1 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
          </React.Fragment>
        ))}
      </Group>

        <Paper p="md" withBorder>
          <Title order={1} mb="xl">New! Version 1.0.4 Beta Release Notes</Title>

          <Title order={3} mt="lg">Updates Since Demo Video:</Title>
          <List>
            <List.Item>(29 Nov 2024) Added "Distribution" page to Channels section, showing the views distribution for a channel over time, including a "Contribution %" view</List.Item>
            <List.Item>(22 Nov 2024) Critical Days ("Going Critical...") modelling shows the good/better than good days for a channel during the tracking period and breaks it down by contribution per video. As with other analyses, this should get a bit smarter over time (where "get a bit smarter" == "look less off") and the "untracked" bars should also get smaller as the % of the library tracked across channels increases</List.Item>
            <List.Item>(22 Nov 2024) "Going Critical..." is available for certain channels and will update daily</List.Item>
            <List.Item>(19 Nov 2024) "Swarm" charts under performance now include video length as a new option</List.Item>
            <List.Item>(11 Nov 2024) Updated "Unicorn" parameters for video classification to make more sense, and updated the area on the chart to show this</List.Item>
            <List.Item>(11 Nov 2024) New chart on the channels page: Normalized Views. This shows the views from the most recent videos (published on the date, date-1 day and date-2 days) with the remainder as the channel baseline</List.Item>
            <List.Item>(6 Nov 2024) Top 5s now show classifications for videos where available</List.Item>
            <List.Item>(5 Nov 2024) View all classified videos on the Classifications page in the Channels/Channel section. This data is not available for all channels yet, and should get better over time.</List.Item>
            <List.Item>(5 Nov 2024) Video classifications are included on the Video page and Video Comparison page (where available)</List.Item>
            <List.Item>(5 Nov 2024) Introducing Classification. Videos (where they have the available data) are now classified as Fossils 🪨; Zombies 🧟‍♂️; Rockets 🚀; Evergreen 🌲; Evergreen Plus 🎄; Stars ⭐️ and Unicorns 🦄</List.Item>
            <List.Item>(4 Nov 2024) Channel Networks are now filterable by Edge Type AND Source Content Age. Channel Networks can also be set to fullscreen for easier exploration</List.Item>
            <List.Item>(3 Nov 2024) Docs updated over on Notion and API access requests are now live! You can request access to the API by clicking the "Request API Access" button in the menu (top right)</List.Item>
            <List.Item>(3 Nov 2024) Compare Channels daily views and content strategy over time on "Compare Channels" page</List.Item>
            <List.Item>(2 Nov 2024) New chart type on channels showing total views on the date with videos published (broken down by content type). This is the first option on channels that is selected on load in. There are 8 chart options on this page now</List.Item>
            <List.Item>(2 Nov 2024) Experimental Analyses is now "Video Analysis" in the Channels section and has improved (v2) Weibull calculations focussing on daily performance rather than cumulative performance, with improved labelling, tooltips and info</List.Item>
            <List.Item>(2 Nov 2024) Channel Networks are now filterable by "edge type" meaning that you can filter by the type of connection between nodes. Note that there is a backend bug for some channels affecting the ingest of endscreen and shorts_related connections/edges</List.Item>
          </List>

          <Title order={3} mt="lg">Next Up / Roadmap</Title>
          <List>
            <List.Item>Thumbnail analysis and scores via Google Cloud Vision API</List.Item>
          </List>

          <Title order={2} mt="xl">v1.0.3 Beta includes:</Title>

          <Text mb="md">Demo & Run-Through</Text>
          <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%' }}>
            <iframe 
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              src="https://www.youtube.com/embed/AU4Zk9rlC_M" 
              title="YouTube video player" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen>
            </iframe>
          </div>

          <Title order={3} mt="lg">Channels:</Title>
          <List>
            <List.Item>Channel - fully featured page with data for fully tracked channels over time, including charts, yesterday's top 10 (by views gained and total views)</List.Item>
            <List.Item>Top 5 - top 5s by views for each content type on each fully tracked channel</List.Item>
            <List.Item>Video - performance data for videos on each fully tracked channel over time, including total performance, daily change (views, like and comments), and relative performance (compared to the min and max for the content type on the channel)</List.Item>
            <List.Item>Compare Videos - compare two videos from across the range of fully tracked channels (views only for now!)</List.Item>
            <List.Item>Networks & Subgraphs - full networks for channels, Subgraphs are searchable by node for each channel network</List.Item>
            <List.Item>Timeline - content types and topics over time</List.Item>
            <List.Item>2D, Treemap & Swarm - content performance by a range of measures</List.Item>
            <List.Item>Compare 2D - compare two channels by a range of metrics in the performance section</List.Item>
            <List.Item>Data - full data for all tracked videos for a channel</List.Item>
          </List>

          <Title order={3} mt="lg">Trending:</Title>
          <List>
            <List.Item>Yesterday - top 50 videos trending in the UK & US by day</List.Item>
            <List.Item>Performance - views, comments and conversation rate for the trending videos in the US & UK</List.Item>
            <List.Item>Networks - networks of trending content each day</List.Item>
            <List.Item>Data - full data for trending videos each day</List.Item>
          </List>

          <Title order={3} mt="lg">Experiments:</Title>
          <List>
            <List.Item>Model Explorer - the UMAP projection of the model data</List.Item>
            <List.Item>Source / Subsidiary - aims to show the value of additional (subsidiary/derivative content) from the channel's main (podcasts in this case) content. Requires well-labelled data and currently only available for Dwarkesh Patel</List.Item>
            <List.Item>Analyses - some experimental analyses of video performance over time, including Weibull (shows the popularity/views of the content over time), Alpha (views of the content against the channel's baseline... Is this video over- or under-performing?) and Beta (volatility of the content over time - are views fluctuating more or less than the channel's typical content)</List.Item>
          </List>

          <Text mt="xl" fs="italic">
            New data comes through each evening UK time, and everything is updated daily.
          </Text>

          <Text mt="xl" fs="italic">
            The full documentation and guides are on Notion and linked by page in the Help Directory.
          </Text>

        </Paper>
    </Stack>
  );
}